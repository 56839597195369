#App-anon {
  display: flex;
  flex-direction: column;
  /*background-color: rgba(242, 242, 242, 0.9765);*/
  font-family: Nunito;
  min-height: 100vh;
  min-width: 600px;
  width: 100vw;
  min-height: 100vw;
}

#App-header-anon {
  min-height: 117px;
  display: flex;
  /*flex-direction: row;*/
  align-items: center;
  justify-content: left;
  font-style: normal;
  color: var(--main_header_background_color);
  background-color: rgba(249, 249, 249, 1);
  left: 0;
  flex-flow: row wrap;
  padding: 0 2em 0 2em;
  /*position: sticky;*/
}

.Button-header {
  min-width: 220px;
  font-size: 20px;
  padding: 0 2em 0 2em;
}

.imageMain2 {
  grid-row-start: 1;
  grid-row-end: 4;
  width: 30vw;
  max-width: 300px;
}

.imageMain3 {
  grid-row-start: 1;
  grid-row-end: 4;
  grid-column: 2;
  width: 30vw;
  max-width: 300px;
}

#PageTitle-anon {
  margin-left: 1em;
  margin-right: 1em;
  font-size: 24px;
  font-weight: 800;
  color: rgba(87, 89, 90, 1);
}

#App-menu-anon {
  margin: 0;
  min-height: 67px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  /*font-size: 20px;*/
  font-size: clamp(16px,1.7vw,20px);
  color: white;
  background: linear-gradient(to right, rgba(93, 105, 214, 0.7), rgba(169, 80, 214, 0.7));
  justify-content: center;
  align-items: center;
  left: 0;
  position: sticky;
  border-bottom: 3px solid rgba(205, 205, 205, 1);
  border-top: 3px solid rgba(205, 205, 205, 1);
}

#App-menu-anon a {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: clamp(16px,1.7vw,20px);
  /*font-size: 20px;*/
  color: white;
  text-align: center;
  min-width: 8em;
  max-width: 9em;
  margin: 0;
}

#App-menu-anon a.active {
  height: 67px;
  background: linear-gradient(to right, rgba(112, 99, 214, 1), rgba(142, 89, 214, 1));
  font-weight: 800;
}

#App-menu-anon a.pending {
  height: 67px;
  font-weight: 700;
}

#Content-container {
  /*margin: 2.5em 6em 4em 6em;*/
  margin: 3vh 7vw 5vw 7vw;
  /*width: 100vw;*/
  /*
  display: grid;
  grid-template-columns: auto 400px;
  */
}

#Content-container > h1 {
  margin-bottom: 0;
  /*font-size: 36px;*/
  font-size: clamp(20px,1.7vw,36px);
  font-weight: 800;
  color: rgba(48, 48, 48, 1);
}

#Content-container > h2 {
  margin-top: 0;
  margin-bottom: 1em;
  /*font-size: 26px;*/
  font-size: clamp(16px,1.7vw,26px);
  font-weight: 600;
  color: rgba(68, 68, 68, 1);
}

#Content-container > span {
  /*font-size: 26px;*/
  font-size: clamp(16px,1.7vw,26px);
  font-weight: 600;
  line-height: 1.423;
  color: rgba(68, 68, 68, 1);
  text-align: justify;
  width: 960px;
}



#About-text{
  /*margin: 3vw 0 0 0;*/
  /*background-color: rgba(255, 255, 255, 0.8);*/
  /*border-radius: 30px;*/
  padding: 5vh 2vw 2vh 2vw;
  column-gap: 2em;
  overflow: auto;
  /*box-shadow: 4px 4px 30px rgba(0, 0, 0, 0.25);*/
  /*display: flex;*/
}

#About-text > img {
  /*
  grid-row-start: 1;
  grid-row-end: 4;
  grid-column: 2;
  */
  display: block;
  border-radius: 36px;
  border: solid 1px rgba(0, 0, 0, 1);
  float: right;
  width: 30vw;
  margin: 0 0 2vw 2vw;
  max-width: 400px;
}
/*
#About-text-text {
    float: left;
    display: inline;
}
*/

.hd1 {
  display: inline;
  margin-bottom: 0;
  /*font-size: 36px;*/
  /*font-size: 1.75vw;*/
  font-size: clamp(20px,1.7vw,36px);

  font-weight: 800;
  color: rgba(48, 48, 48, 1);
}

.hd2 {
  display: block;
  /*font-size: 28px;*/
  /*font-size: 1.55vw;*/
  margin: 1.5vw 0 0vw 0;
  font-size: clamp(16px,1.7vw,26px);
  font-weight: 600;
  color: rgba(68, 68, 68, 1);
}

#About-text > span {
  display: inline;
  /*font-size: 26px;*/

  /*font-size: 1.55vw;*/
  font-size: clamp(16px,1.7vw,26px);
  font-weight: 600;
  line-height: 1.423;
  color: rgba(68, 68, 68, 1);
  text-align: justify;
}
/*
@media screen and (min-width: 680px) {
 .hd1 { 
 font-size: 20px;
  }
  .hd2 { 
 font-size: 18px;
  }
  #About-text > span {
    font-size: 18px;
  }
}
*/
.News-Main-Contaner
{
 padding-top: 3vh;
}

.NewsPart
{
  background-color: rgba(255, 255, 255, 0.8);
  border-radius: 30px;
  padding: 4vh 4vw 4vh 4vw;
  margin: 3vh 0 0 0;
   box-shadow: 4px 4px 30px rgba(0, 0, 0, 0.25);
   overflow: auto;
}

.NewsPart > img
{
  display: block;
  border-radius: 36px;
  border: solid 1px rgba(0, 0, 0, 1);
  float: left;
  /*width: 30vw;*/
  /*height: 35vh;*/
  margin: 0 2vw 2vw 0;
  /*max-width: 300px;*/
}

.NewsPart > .nvcont
{
 display: flex;
 flex-direction: column;
 height: 35vh;
}
/*
.nvcont > .nvpuff
{
  flex-grow: 1;
}
*/


.NewsPart > p
{
  font-size: clamp(14px,1.6vw,22px);
  margin: 0vh 0 0vh 0;
  text-align: left;
  white-space: pre-wrap;
}

.NewsPart > h1
{
   font-size: clamp(16px,1.7vw,26px);
    margin: 0vh 0 3vh 0;
    display: block;
}


.italic_font
{  
  font-style: italic;
}
.NewsPart > .data_setts
{
  margin-top:5vh;
  font-size: clamp(12px,1.4vw,20px);
  font-weight: 400;
}

.float-bottom
{
 /*position: absolute;
 bottom: 0;
 */
  margin-top: 4vh;
 }


#Content-graduate {
  margin: 2em 0 0 0;
  display: grid;
  background-color: rgba(255, 255, 255, 0.8);
  grid-column-start: 1;
  grid-column-end: 3;
  border-radius: 30px;
  padding: 2.25em 2.25em 2.25em 2.25em;
  /*grid-template-columns: 300px auto;*/
  column-gap: 2em;
  box-shadow: 4px 4px 30px rgba(0, 0, 0, 0.25);
}

#Content-graduate > h1 {
  margin-top: 0;
  margin-bottom: 0;
  grid-column-start: 2;
  /*font-size: 36px;*/
  font-size: clamp(20px,1.7vw,36px);
  font-weight: 700;
  color: rgba(48, 48, 48, 1);
}

#Content-graduate > span {
  grid-column-start: 2;
  margin-top: 0;
  /*font-size: 26px;*/
  font-size: clamp(16px,1.7vw,26px);
  font-weight: 600;
  line-height: 1.423;
  color: rgba(85, 85, 85, 1);
  text-align: justify;
  justify-self: start;
}

#Content-graduate > .Button-header {
  margin: 0;
  min-width: 260px;
  height: 60px;
  align-self: flex-end;
  justify-self: end;
}

#Content-org {
  margin: 2em 0 0 0;
  display: grid;
  background-color: rgba(255, 255, 255, 0.8);
  grid-column-start: 1;
  grid-column-end: 3;
  border-radius: 30px;
  padding: 2.25em 2.25em 2.25em 2.25em;
  grid-template-columns: auto 300px;
  column-gap: 2em;
  box-shadow: 4px 4px 30px rgba(0, 0, 0, 0.25);
}

#Content-org > h1 {
  margin-top: 0;
  margin-bottom: 0;
  grid-column-start: 1;
  /*font-size: 36px;*/
  font-size: clamp(20px,1.7vw,36px);
  font-weight: 700;
  color: rgba(48, 48, 48, 1);
}

#Content-org > span {
  grid-column-start: 1;
  margin-top: 0;
  /*font-size: 26px;*/
  font-size: clamp(16px,1.7vw,26px);
  font-weight: 600;
  line-height: 1.423;
  color: rgba(85, 85, 85, 1);
  text-align: justify;
  justify-self: start;
}

#Content-org .Button-header {
  margin: 0;
  min-width: 260px;
  height: 60px;
  align-self: flex-end;
  justify-self: start;
}

#Content-advantages {
  margin: 2em 0 0 0;
  display: grid;
  background-color: rgba(255, 255, 255, 0.8);
  grid-column-start: 1;
  grid-column-end: 3;
  border-radius: 30px;
  padding: 2.25em 2.25em 2.25em 2.25em;
  grid-template-columns: 80px auto;
  column-gap: 2em;
  row-gap: 2em;
  box-shadow: 4px 4px 30px rgba(0, 0, 0, 0.25);
}

#Content-advantages > h1 {
  margin-top: 0;
  margin-bottom: 0;
  grid-column-start: 1;
  grid-column-end: 3;
  /*font-size: 36px;*/
  font-size: clamp(20px,1.7vw,36px);
  font-weight: 700;
  color: rgba(48, 48, 48, 1);
  align-self: center;
  justify-self: center;
}

#Content-advantages > div {
  display: flex;
  background-color: rgba(96, 104, 214, 0.3);
  min-width: 80px;
  min-height: 80px;
  grid-column: 1;
  border-radius: 20px;
  align-items: center;
  justify-content: center;
}

#Content-advantages > span {
  /*font-size: 26px;*/
  font-size: clamp(16px,1.7vw,26px);
  font-weight: 600;
  color: rgba(85, 85, 85, 1);
  grid-column-start: 2;
  align-self: center;
}

#Content-container-org {
  margin: 2.5em 6em 4em 6em;
}

#Content-container-org > h1 {
  margin-bottom: 0;
  /*font-size: 36px;*/
  font-size: clamp(20px,1.7vw,36px);
  font-weight: 800;
  color: rgba(48, 48, 48, 1);
}

#Important-org {
  margin: 2em 0 0 0;
  display: inline-block;
  grid-row: 2;
}

#Org-link {
  margin-right: 5px;
}

#Org-divider {
  margin: 0 15px 9px 0;
}

#App-footer {
  display: grid;
  grid-template-columns: 20% 20% 16% 16% 28%;
  min-height: 184px;
  background-color: rgba(58, 58, 58, 1);
  padding-left: 2em;
}

#App-footer > #Footer-cont {
  display: flex;
  font-size: 14px;
  font-weight: 500;
  color: white;
  border-right: 1px solid rgba(169, 80, 214, 1);
  height: 39px;
  align-self: center;
  align-items: center;
  justify-content: center;
}

#App-footer > #Personal-data-protect {
  display: flex;
  font-size: 12.5px;
  font-weight: 500;
  line-height: 1.568;
  align-self: center;
  align-items: center;
  justify-content: center;
  color: white;
}

.App-main-anon {
  background: linear-gradient(to bottom right, rgba(91, 104, 213, 0.35), rgba(172, 78, 213, 0.35));
  backdrop-filter: blur(4px);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 30px;
  color: var(--dark_color_for_letters);
  padding-top: 1.8vh;
}

#App-main-anon > h1 {
  font-size: clamp(20px,1.7vw,32px);
  font-weight: 700;
  text-align: center;
  color: black;
}

#Content-container-owner {
  margin: 2vw;
  /*
  display: flex;
  flex-wrap: wrap;
*/
  /*grid-template-columns: 420px auto;*/
  column-gap: 60px;
}

#Mail-content {
  text-align: center;
  display: inline-block;
  margin: 2em 2em 2em 0;
  background-color: rgba(255, 255, 255, 0.8);
  /*grid-column: 1;*/
  border-radius: 30px;
  padding: 2.25vh 5vw 2.25vh 5vw;
  box-shadow: 0px 4px 17px rgba(0, 0, 0, 0.17);
  /*float: left;*/
  width: 420px;
}

#Mail-content > h1 {
  /*font-size: 16px;*/
  font-size: clamp(12px,1.7vw,16px);
  font-weight: 700;
  color: black;
}

#Mail-form {
  display: grid;
}

#Mail-form > label {
  justify-self: left;
  color: rgba(0, 0, 0, 0.75);
  font-size: clamp(10px,1.7vw,12px);
  /*font-size: 12px;*/
  font-weight: 400;
  margin: 0.75vw;
}

#Mail-form > #Text-field {
  border: 1px solid rgba(230, 230, 230, 1);
  border-radius: 7px;
  height: 48px;
  font-family: "Roboto";
  font-size: 15px;
}

#Mail-form > #Date-field {
  border: 1px solid rgba(230, 230, 230, 1);
  border-radius: 7px;
  height: 48px;
  font-family: "Roboto";
  font-size: 15px;
}

#Mail-form > button {
  margin-top: 2em;
  box-shadow: none;
  background-color: rgba(152, 90, 206, 1);
  color: white;
  border-radius: 7px;
  border: none;
  height: 48px;
  font-size: 12px;
  font-weight: 700;
}

#Owner-info {
  text-align: center;
  margin: 2em 0 0 0;
  background-color: rgba(255, 255, 255, 0.8);
  /*grid-column: 2;*/
  border-radius: 30px;
  padding: 2.25em 5em 2.25em 5em;
  box-shadow: 0px 4px 17px rgba(0, 0, 0, 0.17);
}

#Owner-info > h1 {
  margin: 0;
  font-size: 20px;
  font-weight: 700;
  color: black;
}
