
.simpleTable {
  border-spacing: 0;
  padding: 1em 2em;
}

.simpleTable > * > tr > * {
  border: 1px solid var(--table_border_color);

}
.simpleTable > thead {
  position: sticky;
  top: 0;
  z-index: 1;
}


.simpleTable > tbody > tr[modified] > td,
.simpleTable > tbody > tr[modified] > td > a[href],
.simpleTable > tbody > tr[modified] > td > div > a[href] {
  font-weight: bold;
}

table.simpleTable 
, table.simpleTableWithForm
{
  width: 100%;
}

table.simpleTable > tbody > tr > td,
.simpleTable a[href], .simpleTable a[href]:visited{
  color: var(--dark_color_for_letters);
}

.withHiddenCols > tbody > tr > td[hidden-col]
, .withHiddenCols > thead > tr > th[hidden-col]
, .simpleTable > tbody > tr > td[hidden-col]
, .simpleTable > thead > tr > th[hidden-col] {
  visibility: collapse;
  width: 0;
  max-width: 0;
  max-height: 1px;
  padding: 0;
  border: none;
}

.simpleTable > thead > tr > th{
  background: var(--dark_color_for_letters);
  color: #FFFFFF;
}
.simpleTable th.del-paddings-del-button{
  width: 6em;
}

.simpleTable > tbody > tr > td,
.simpleTable > thead > tr > th{
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 18px;
  text-align: left;
  padding: 0.5em 1em;
}

.simpleTable > tbody > tr{
  background: var(--main_background_color);
}


.subTable + .add {
  margin-left: 1em;
}

.simpleTableWithForm > thead > tr > th:first-child
, .simpleTableWithForm > tbody > tr > td:first-child
{
  width: 2em;
}

.simpleTableWithForm > tbody > tr.SuperTableForm > td {
  padding-left: 2em;
  padding-bottom: 2em;
  border-bottom: 2px solid;
}

.MultiRowOpHeader {
  text-align: left;
}
.simpleTable.subTable{
  width: 80%;
  padding: 1em;
}
.subTable > thead{
  display: none;
}
