:global(.PopupFrame)
, .alertBox
, .confirmBox
, .promptBox
{
  padding: 2ex;
  background: var(--submenu_main_color);
  border-radius: 10px;
  outline: none;
  color: var(--main_background_color);
}

.promptBox > div > input{
  border: none;
  height: 2em;
  border-radius: 5px;
  background: rgba(233, 235, 243, 0.9);
  margin-bottom: 1em;
  width: 50em;
  max-width: 90vw;
}

.alertBox
, .confirmBox {
  min-width: 10em;
  text-align: center;
}

.alertBox > div:first-child
, .confirmBox > div:first-child 
{
  color: var(--main_table_background_color);
  margin: 1ex 0 1em 0;
}

.alertBox > button, .confirmBox > div > button
,.promptBox > div > div:last-child>button
{
  border: none;
  background: var(--submenu_main_color);
  padding: 5px 1em;
  box-shadow: none;
  margin: auto;
}
.confirmBox > div {
  margin-top: 1em;
  text-align: center;
  display: flex;
  justify-content: center;
}

.promptBox {
  min-width: 15em;
}

.promptBox > div > div:first-child {
  color: var(--main_table_background_color);
  margin-bottom: 0.5em;
}

.promptBox > div > div:last-child
, :global(.PopupFrame) > form > div > div:global(.flexContainer):last-child
, :global(.PopupFrame) > form > div:global(.flexContainer):last-child
{  /*fixme*/
  text-align: center;
  display: flex;
  justify-content: center;
}
:global(.PopupFrame) > form > div:global(.flexContainer):not(:last-child) > *{
  margin: 0 1em 0 0;
}
:global(.PopupFrame) > form > div:global(.flexContainer):not(:last-child){
  margin-top: 0.5em;
}

.promptBox > div > div:last-child>button:first-child {
  margin-right: 1ex;
}
.promptBox > div > div:last-child>button:last-child {
  margin-left: 1ex;
}

:global(.PopupFrame[close-box])
, .PopupFrame[close-box]
, .alertBox[close-box]
, .confirmBox[close-box]
, .promptBox[close-box]
{
  padding-top: 0;
}

.closeBox {
  width: 100%;
  height: 30px;
  box-sizing: border-box;
  padding: 5px;
  
  background: white;
  display:block;
  
  z-index: 1;
  color: gray;
  cursor: pointer;

  position: sticky;
  top: 0;
  left: 0;
  border-bottom: 1px solid;
}
.closeBox:after {
  display: block;
  content: "✕";
  text-align: right;
}

.x-closeBox {
  width: 30px;
  height: 30px;
  right: -10px;
  top: -10px;
  

  position: absolute;
  background: transparent;
  display:inline-block;
  
  z-index: 1;
  line-height:0.5em;
  color: gray;
  text-align:center;
  cursor: pointer;
}

.x-closeBox:after {
  content: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAeCAYAAAA7MK6iAAAABGdBTUEAAK/INwWK6QAAABl0RVh0U29mdHdhcmUAQWRvYmUgSW1hZ2VSZWFkeXHJZTwAAAQrSURBVHjaxFdrKGVRFF4e3fJD6pYoUUrdqIkSk9t4JDMhz3F/zA9JihAlEYOSd3lFnr8pqdHI+GG6CodyI2qYulE3U1eUR8MlTd6z9p57dvucOffew8w0u77OfqyzvrXOXmvvddweHx/hfzRPtYKjo6Mv0MhsxGuEF05FEKMRXxA/cDz/8PAwXVFR8VWNPjdXHg8PDxOyDoROlLUTSsDN7yIaqqurp55FPDg4GIRrk4gYMkZvfiPl5+VPXDch3tXX11tVE/f398fj/EeE1pmXroDtOxrytqmpSXBJ3NvbS0iN2NU48UYV7PI32H/T3t4uOCTu6uoKxvEawleFN1SxSiNOUPZlT0/PN5HLnbfi7u5uDOGLABHR0dGQkZFBFfDzNzc3rH97e8ueKSkpkJCQQPsiUJboHFNMp5aWFgMqf8V7ExkZCUlJSXSs0WhgYmKCKnLwSSEzMxNiY2Np//7+Hubm5tg60V1SUmIYGRmZkniMFrXKvfHz82MW6nQ6yM/PBw8PD7k3VDY9PR3i4uKYvL+/PyWXybZK9hhDngTUktL+kE+XmJjIFB4cHABaDTabjXljMBgkMpubm+TAYTEgi4WE8fFxgXqM1iTzlvHezMzMwPT0NFMaEBAA5eXl4O3tTWVycnIkpBsbGzA0NATX19f0fQKZzmS2xzgR4ygdyNNoNMLl5SXk5uaCu7s73YKqqirY2dkBvV7PSNfX12FgYIC+J494bhzDE4c5IyZYWlqCi4sLKC4uBk9PT9BqtRLStbU16Ovro/sqDzqZrjCe2MeFMIXJZKLkNTU1NMjEZjabobu7W0Kq9L5dtw9P7FBYPh8RESEhJS0kJATCw8Op12p0sHRCYps8CPgAE9fy8vIgNTWVEZJ5Mcfr6upoOikEk3zOxohxwSwK8U8eJIfT0tIY6crKChQWFsLh4eEvDzDo8Cqkp5wjB+x9M++xyZmnBQUFVKHYlpeXobm5GaxWK5SVlYHFYmFrZEy+jBK5HSae+LODz0K9ysrKYooFQYDGxkaap2T96OgISktLYWtri8kQQysrKx0RzzPi2dlZAQl35R4XFRVBdnY2U7i4uEhOOUYq4uzsjJKvrq4y2aurK6Ut293e3l6QXBI42YAR94GPQHJoiG1hYYGSknNZ6VokJOREa2tro+91dnZKotjeb1C8j/FAWMHHKz70idfBwcFQW1tL85QvCpRShgSZeIXK8te0t7enVySOiooKwTH5Xr7OcpEnVlkQnCD0+/v7FoelDx4Q8ajIiPOaJ5Q3zmRo6YNpJ7gs9kJDQ2mxh13tE8obpfqMFnvHx8eC6vIWj8EgfGkSuzHPrDBpeXt6emp9VkEfGBhISqIOYouaqhObBeffY4pN/dGfhNjwDo5E2QzyC4NDL3xKfmEQ5GD4dH5+vvlXfmH+VfspwAAZWjjqYwWNDAAAAABJRU5ErkJggg=='); 
}
