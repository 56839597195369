.labeledField {
  position: relative;
}

.labeledField > label:first-child {
  display: block;
  /*color: var(--dark_color_for_letters);
  font-size: 20px;*/
}

.labeledField > label:first-child:not(:empty) {
  margin: 0.5em 0em;
}
fieldset .labeledField > label:first-child{
  font-style: normal;
  font-weight: normal;
  font-size: 1em;
  line-height: 12px;
  color: var(--submenu_main_color);
}
.labeledField > .tip {
  color: #888;
  font-size: 80%;
}

.labeledField >  input 
,.labeledField > select 
,.labeledField > textarea 
,.labeledField > button
,.labeledField > output
{
  color: var(--dark_color_for_letters);
  background: var(--main_background_color);
  border-radius: 4px ;
  width: 100%;
  border: none;
  box-sizing: border-box;
  resize: none;
  box-shadow: none;
  justify-content: left;
  min-height: 2.5em;
  display: flex;
  padding-left: 1em;
  align-items: center;
}

.labeledField > select {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  white-space: pre-wrap;
  color: var(--letters_in_search_bar_color);
  box-shadow: none;
  font-weight: normal;
}
.labeledField > select::-ms-expand {
        display: none;
}

.labeledField > div > input[type="checkbox"] {

}

.labeledField[undecorated=""] >  input 
,.labeledField[undecorated=""] > select 
,.labeledField[undecorated=""] > textarea 
,.labeledField[undecorated=""] > button
,.labeledField[undecorated="filled"] > input:not([value=""]) 
,.labeledField[undecorated="filled"] > select:not([value-reflect=""]) 
,.labeledField[undecorated="filled"] > textarea:not([value=""]) 
,.labeledField[undecorated="filled"] > button:not(:empty)
{
  border: none;
  background: none;
  box-shadow: none;
  color: var(--dark_color_for_letters);
}
:global(.PopupFrame) .labeledField[undecorated=""] > button{
  color: var(--main_background_color);
}

td > .labeledField:first-child:last-child > div > input[type="checkbox"] {
  display: block;
  margin: 0 auto 0 auto;
}
td > .labeledField >  input 
,td > .labeledField > select 
,td > .labeledField > textarea 
,td > .labeledField > button
{
  border: none;
  box-shadow: none;
  color: var(--dark_color_for_letters);
  font-weight: normal;
}

td > .labeledField > select[value-reflect=""]
,td > .labeledField > select:not([value-reflect]) {
  background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4NCjwhLS0gU3ZnIFZlY3RvciBJY29ucyA6IGh0dHA6Ly93d3cub25saW5ld2ViZm9udHMuY29tL2ljb24gLS0+DQo8IURPQ1RZUEUgc3ZnIFBVQkxJQyAiLS8vVzNDLy9EVEQgU1ZHIDEuMS8vRU4iICJodHRwOi8vd3d3LnczLm9yZy9HcmFwaGljcy9TVkcvMS4xL0RURC9zdmcxMS5kdGQiPg0KPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IiB2aWV3Qm94PSIwIDAgMTAwMCAxMDAwIiBlbmFibGUtYmFja2dyb3VuZD0ibmV3IDAgMCAxMDAwIDEwMDAiIHhtbDpzcGFjZT0icHJlc2VydmUiPg0KPG1ldGFkYXRhPiBTdmcgVmVjdG9yIEljb25zIDogaHR0cDovL3d3dy5vbmxpbmV3ZWJmb250cy5jb20vaWNvbiA8L21ldGFkYXRhPg0KPGc+PGc+PGcgaWQ9IlJlY3RhbmdsZV8xMSI+PGc+PHJlY3QgeD0iMTAiIHk9IjQ4Mi41IiB3aWR0aD0iOTgwIiBoZWlnaHQ9IjM1Ii8+PC9nPjwvZz48L2c+PGc+PC9nPjxnPjwvZz48Zz48L2c+PGc+PC9nPjxnPjwvZz48Zz48L2c+PGc+PC9nPjxnPjwvZz48Zz48L2c+PGc+PC9nPjxnPjwvZz48Zz48L2c+PGc+PC9nPjxnPjwvZz48Zz48L2c+PC9nPg0KPC9zdmc+);
  background-repeat:  no-repeat;
  background-size: 1em;
  background-position: center;
}

.labeledField[required] > label:not(:empty):after {
  content: '*';
  color: var(--color_warning_letters);
  font-size: 60%;
  position: relative;
  top: -5px;
  display: inline-block;
}

.formButtons {
  margin-top: 1em;
}

.formButtons > *:not(:first-child) {
  margin-left: 1ex;
}

.labeledField > .error {
  color: var(--color_warning_letters);
}

.tip {
  
}

:global(.info) {
  background: #5ce25e;
  border: 2px solid #255809;
  color: #09023b;
}

:global(.danger) {
  background: rgba(224, 113, 107, 0.8);
  border: none;
  color: var(--main_background_color);
  box-shadow: none;
}