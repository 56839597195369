
.inlineSelect > button {
  border: none;
  background: transparent;
}

.inlineSelect > *:not(:first-child) {
  margin-left: 1ex;
}

.inlineSelect > .selected {
  border-bottom: 1px solid;
  font-weight: bold;
}

button.editWithPrompt {
  display: flex;
  background: var(--main_background_color);
  border-radius: 4px ;
  border: none;
  box-sizing: border-box;
  resize: none;
  box-shadow: none;
  padding: 0 1em;
}
:global(.PopupFrame) button.editWithPrompt{
  min-height: 2em;
}
.popupEdit {
	
}

.arrow, .arrow_up, .arrow_down {
  color: var(--dark_color_for_letters);
  border: none;
  box-shadow: none;
  background: transparent;
  display: inline-block;
  width: 1em;
  cursor: pointer;
  font-size: 18px;
}
:global(.PopupFrame) .arrow, .arrow_up, .arrow_down, :global(.PopupFrame) .arrayString {
  color: var(--main_background_color);
}

.arrow[class~="up"] {
  margin-right: 1.5em;
}
.arrow[class~="down"] {
  margin-left: 1em;
  margin-right: .5em;
}
.arrow[class~="up"] + .arrow[class~="down"] {
  margin-left: 1em;
  position: absolute;
}

.arrayString {
  color: var(--dark_color_for_letters);
  border: none;
  box-shadow: none;
  background: transparent;
}
