.PopupFrame{
    all: initial;
}

#modals > div.shadow:nth-last-child(2) {
    background: none;
    backdrop-filter: blur(5px);
}

.registration-container {
    display: flex;
    justify-content: center;
}

.resend-code-container {
    display: flex;
    align-items: center;
    margin-top: auto;
}

.resend-info{
    font-family: 'Nunito', sans-serif;
    font-weight: 400;
    font-size: 10px;
    text-align: r;
    color: rgba(64, 58, 75, 0.6);
    width: 133px;
}

.form-wrapper .resend-code-button {
    max-width: 108px;
    background-color: #8590FF;
    margin-left: auto;
    font-size: 10px;
}

.form-wrapper .resend-code-button:hover {
    background-color: #6b75d4;
}

.logo-container {
    position: relative;
}

.registration-background {
    position: relative;
    z-index: 1;
    top: 50%;
    left: 50%;
    transform: translate(-50%);
}

.registration-logo {
    position: absolute;
    z-index: 2;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.form-email {
    font-family: 'Roboto', sans-serif;
    font-weight: 500;
    font-size: 12px;
    text-align: center;
    fill: #403A4B;
}

.separator-line {
    vertical-align: middle;
    margin: 1em 0 1em 0;
}

.reset-password {
    font-family: 'Nunito', sans-serif;
    font-weight: 500;
    font-size: 12px;
    color: black;
    text-align: center;
    cursor: pointer;
    margin-top: auto;
}

.error {
    color: var(--color_warning_letters);
}

.custom-date-picker-container {
    display: flex;
}

.custom-date-picker-container button {
    max-width: 3em;
    max-height: 2em;
    margin: auto;
}

.date-picker-input {
    max-width: 120px;
}

.date-picker-icon {
    cursor: pointer;
    fill: #5E69D6;
    margin-left: -40px;
    z-index: 2;
}

.date-picker-icon:hover {
    fill: #4B54B1;
}

.custom-calendar .react-datepicker__day--selected, .react-datepicker__day--keyboard-selected {
    background-color: #5E69D6;
    color: #FFFFFF;
}

.custom-calendar .react-datepicker__day--selected:hover, .react-datepicker__day--keyboard-selected:hover {
    background-color: #4B54B1;
}
