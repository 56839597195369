/* image sizes lol, for news resize translation*/
.img_wsz_0{width: 0vw;}
.img_wsz_1{width: 1vw;}
.img_wsz_2{width: 2vw;}
.img_wsz_3{width: 3vw;}
.img_wsz_4{width: 4vw;}
.img_wsz_5{width: 5vw;}
.img_wsz_6{width: 6vw;}
.img_wsz_7{width: 7vw;}
.img_wsz_8{width: 8vw;}
.img_wsz_9{width: 9vw;}
.img_wsz_10{width: 10vw;}
.img_wsz_11{width: 11vw;}
.img_wsz_12{width: 12vw;}
.img_wsz_13{width: 13vw;}
.img_wsz_14{width: 14vw;}
.img_wsz_15{width: 15vw;}
.img_wsz_16{width: 16vw;}
.img_wsz_17{width: 17vw;}
.img_wsz_18{width: 18vw;}
.img_wsz_19{width: 19vw;}
.img_wsz_20{width: 20vw;}
.img_wsz_21{width: 21vw;}
.img_wsz_22{width: 22vw;}
.img_wsz_23{width: 23vw;}
.img_wsz_24{width: 24vw;}
.img_wsz_25{width: 25vw;}
.img_wsz_26{width: 26vw;}
.img_wsz_27{width: 27vw;}
.img_wsz_28{width: 28vw;}
.img_wsz_29{width: 29vw;}
.img_wsz_30{width: 30vw;}
.img_wsz_31{width: 31vw;}
.img_wsz_32{width: 32vw;}
.img_wsz_33{width: 33vw;}
.img_wsz_34{width: 34vw;}
.img_wsz_35{width: 35vw;}
.img_wsz_36{width: 36vw;}
.img_wsz_37{width: 37vw;}
.img_wsz_38{width: 38vw;}
.img_wsz_39{width: 39vw;}
.img_wsz_40{width: 40vw;}
.img_wsz_41{width: 41vw;}
.img_wsz_42{width: 42vw;}
.img_wsz_43{width: 43vw;}
.img_wsz_44{width: 44vw;}
.img_wsz_45{width: 45vw;}
.img_wsz_46{width: 46vw;}
.img_wsz_47{width: 47vw;}
.img_wsz_48{width: 48vw;}
.img_wsz_49{width: 49vw;}
.img_wsz_50{width: 50vw;}
.img_wsz_51{width: 51vw;}
.img_wsz_52{width: 52vw;}
.img_wsz_53{width: 53vw;}
.img_wsz_54{width: 54vw;}
.img_wsz_55{width: 55vw;}
.img_wsz_56{width: 56vw;}
.img_wsz_57{width: 57vw;}
.img_wsz_58{width: 58vw;}
.img_wsz_59{width: 59vw;}
.img_wsz_60{width: 60vw;}
.img_wsz_61{width: 61vw;}
.img_wsz_62{width: 62vw;}
.img_wsz_63{width: 63vw;}
.img_wsz_64{width: 64vw;}
.img_wsz_65{width: 65vw;}
.img_wsz_66{width: 66vw;}
.img_wsz_67{width: 67vw;}
.img_wsz_68{width: 68vw;}
.img_wsz_69{width: 69vw;}
.img_wsz_70{width: 70vw;}
.img_wsz_71{width: 71vw;}
.img_wsz_72{width: 72vw;}
.img_wsz_73{width: 73vw;}
.img_wsz_74{width: 74vw;}
.img_wsz_75{width: 75vw;}
.img_wsz_76{width: 76vw;}
.img_wsz_77{width: 77vw;}
.img_wsz_78{width: 78vw;}
.img_wsz_79{width: 79vw;}
.img_wsz_80{width: 80vw;}
.img_wsz_81{width: 81vw;}
.img_wsz_82{width: 82vw;}
.img_wsz_83{width: 83vw;}
.img_wsz_84{width: 84vw;}
.img_wsz_85{width: 85vw;}
.img_wsz_86{width: 86vw;}
.img_wsz_87{width: 87vw;}
.img_wsz_88{width: 88vw;}
.img_wsz_89{width: 89vw;}
.img_wsz_90{width: 90vw;}
.img_wsz_91{width: 91vw;}
.img_wsz_92{width: 92vw;}
.img_wsz_93{width: 93vw;}
.img_wsz_94{width: 94vw;}
.img_wsz_95{width: 95vw;}
.img_wsz_96{width: 96vw;}
.img_wsz_97{width: 97vw;}
.img_wsz_98{width: 98vw;}
.img_wsz_99{width: 99vw;}
.img_wsz_100{width: 100vw;}
